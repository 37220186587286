import Vue from 'vue';
import {Typeahead} from 'uiv';

import './index.scss';


const filterFormSelector = '#search-form';


const selected = {
    region: document.querySelector(filterFormSelector).getAttribute('data-region'),
    state: document.querySelector(filterFormSelector).getAttribute('data-state'),
    town: document.querySelector(filterFormSelector).getAttribute('data-town'),
    label: document.querySelector(filterFormSelector).getAttribute('data-label')
};


new Vue({

    el: filterFormSelector,

    components: {
        'typeahead': Typeahead
    },

    data: {
        selected: selected
    }

});
