import Cookies from 'js-cookie';
import jQuery from 'jquery';


const csrfToken = Cookies.get('csrftoken');


function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}


jQuery(document).ready(function ($) {

    const activityHref = $('[data-activity-href]').attr('data-activity-href');

    const trackActivity = function (verb, complete) {
        $.ajax(activityHref, {
            beforeSend: function (xhr, settings) {
                if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
                    xhr.setRequestHeader('X-CSRFToken', csrfToken);
                }
            },
            complete: complete,
            data: {'verb': verb},
            method: 'POST'
        });
    };


    // Activity tracking event hooks

    $('a[data-track]').click(function (event) {
        event.preventDefault();
        const href = $(this).attr('href');
        const verb = $(this).attr('data-track');
        trackActivity(verb, function () {window.location.href = href;});
    });


    $('button[data-track]').click(function (event) {
        const verb = $(this).attr('data-track');
        trackActivity(verb);
    });


    // Phone reveal behaviour

    $('[data-phone] > a').hide();

    $('[data-phone] > button').click(function () {
        $(this).hide().siblings('a').show();
    });


});
